@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,500&family=Montserrat:wght@300&display=swap');

html, body {
    height: 100%;
}

:root {
    height: 100%;
}

.comingSoon {
    color: white;
}

.copyrightArea {
    display: flex;
    align-items: center;
    padding: 1rem;
    opacity: .6;
}

.socialArea {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.comingSoonLabels {
    position: absolute;
    top: 37%;
    width: 100%;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
}
